<template>
  <BaseComponent
    title="Liste des membres"
    :breadcrumb="breadcrumb"
  >
    <el-row
      style="margin-left: 4%; margin-right: 4%"
    >
      <el-col
        style="align-content: center;"
      >
        <el-checkbox
          v-model="doesFilterPromo"
          class="marginL"
          @change="updateFilters"
        />
        <span class="marginL">
          Filtrer par promotion :
        </span>
        <el-input-number
          v-model="promotionFilterValue"
          class="marginL"
          controls-position="right"
          :min="2010"
          :max="2100"
          size="mini"
          :disabled="!doesFilterPromo"
          @change="updateFilters"
        />
      </el-col>
      <el-col
        v-if="isAdmin()"
      >
        <el-checkbox
          v-model="doesFilterUser"
          class="marginL"
          @change="updateFilters"
        />
        <span class="marginL">
          Filtrer par compte :
        </span>
        <el-switch
          v-model="userFilterValue"
          style="margin-left: 10px; margin-top: -2px;"
          active-text="Avec compte"
          inactive-text="Sans compte"
          :disabled="!doesFilterUser"
          @change="updateFilters"
        />
      </el-col>
      <el-col>
        <el-checkbox
          v-model="doesFilterName"
          class="marginL"
          @change="updateFilters"
        />
        <span class="marginL">
          Filtrer par nom :
        </span>
        <el-input
          v-model="nameFilterValue"
          style="width: 40%; height: 80%"
          size="mini"
          placeholder="Entrez un nom"
          prefix-icon="el-icon-search"
          :disabled="!doesFilterName"
          @change="updateFilters"
          @input="updateFilters"
        />
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      class="grid"
      :data="currentSelected"
      :stripe="true"
    >
      <el-table-column
        prop="firstname"
        label="Membre"
        sortable
      >
        <template slot-scope="scope">
          <router-link :to="`/membres/${scope.row.id}/voir`">
            <el-button
              type="text"
              size="small"
            >
              {{ scope.row.fullname }}
            </el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="promotion"
        label="Promotion"
        sortable
      />
      <el-table-column
        prop="poste"
        label="Poste"
        sortable
      />
      <el-table-column
        prop="email"
        label="Email"
        sortable
      />
      <el-table-column
        prop="email_junior"
        label="Email Junior"
        sortable
      />
      <el-table-column
        v-if="isAdmin()"
        style="width: 80%"
        label="Créer un compte"
      >
        <template
          slot-scope="scope"
        >
          <el-button
            v-show="isButtonOn(scope.row.id)"
            ref="btn_{{scope.row.id}}"
            size="medium"
            type="warning"
            @click="openAccount(scope.row.id)"
          >
            Ouvrir un compte utilisateur
          </el-button>
          <el-col v-show="!isButtonOn(scope.row.id)">
            <div class="grid-content bg-purple-light" />
          </el-col>
        </template>
      </el-table-column>
    </el-table>
  </BaseComponent>
</template>

<script>
import { registerMemberMixin } from "../mixins/RegisterMember.js"
import { Permissions } from "@/mixins/Permissions"
import Utils from "@/mixins/Utils"
const axios = require("axios")

export default {
  name: "MembresVoir",
  mixins: [registerMemberMixin, Permissions, Utils],
  data () {
    return {
      membres: [],
      currentSelected: [],
      btnsDesactivated: [],
      promotionFilterValue: 2021,
      userFilterValue: false,
      nameFilterValue: "",
      doesFilterPromo: false,
      doesFilterUser: false,
      doesFilterName: false,
      loading: true,
      breadcrumb: [
        {
          name: "Liste des membres",
          link: "/membres"
        }
      ]
    }
  },
  beforeCreate () {
    this.loading = true
    axios.get(
      "/api/membres/",
      {withCredentials: true}
    ).then((res) => {
      res.data.forEach((elt) => elt.fullname = this.nicePeople(elt))
      res.data.forEach((elt) => elt.poste = this.displayPoste(elt))
      this.membres = res.data
      this.currentSelected = this.membres
    }).catch((err) => {
      this.$message({message: "Impossible de récupérer les membres pour le moment : " + err, type: "error"})
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    openAccount (memberID) {
      if(this.isAdmin()) {
        let t=0
        for (let i = 0; i < 200; i++) {
          if (this.membres[i].id == memberID) {
            t=1
            if( ! this.membres[i].user ) {
              this.mixinRegisterMember(axios, this.membres[i])
            } else {
              this.$message({ message: "Une erreur est survenue : le compte était déjà présent.", type: "error" })
            }
          }
        }
        if ( t==0)
          this.$message({ message: "Une erreur est survenue : Utilisateur introuvable.", type: "error" })
      } else {
        this.$message({ message: "Il faut être administrateur pour faire cette action !", type: "error" })
      }
    },
    isButtonOn (ID) {
      let t=0
      for (let i = 0; i < 200; i++) {
        if (this.membres[i].id == ID) {
          t=1
          return ! this.membres[i].user
        }
      }
      if ( t==0)
        this.$message({ message: "Une erreur est survenue : Utilisateur introuvable.", type: "error" })
    },
    refilterUser (displayWithUser) {
      if(displayWithUser)
        this.currentSelected = this.currentSelected.filter(m => m.user != undefined)
      else
        this.currentSelected = this.currentSelected.filter(m => m.user == undefined)
    },
    filterWithPromotion (promotion) {
      this.currentSelected = this.currentSelected.filter(m => m.promotion === promotion)
    },
    filterWithName (name) {
      this.currentSelected = this.currentSelected.filter(m => m.fullname.toLowerCase().includes(name.toLowerCase()))
    },
    updateFilters () {
      this.currentSelected = this.membres
      if(this.doesFilterName) {
        this.filterWithName(this.nameFilterValue)
      }
      if(this.doesFilterPromo) {
        this.filterWithPromotion(this.promotionFilterValue)
      }
      if(this.doesFilterUser) {
        this.refilterUser(this.userFilterValue)
      }
    }
  }
}
</script>

<style scoped>
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .filtresDiv {
    width: 95%;
    height: 8vh;
    border: 1px solid black;
    margin: 1em;
  }
  .marginL {
    margin-left: 12px;
    margin-top: 5px;
  }
</style>
